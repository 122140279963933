/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-18 10:09:58
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-18 10:11:34
 */
import ActivityTest from './ActivityTest.vue'
export default ActivityTest
