/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-18 10:59:28
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-06 17:06:18
 */
import { copyText } from '@/plugins/utils'
import { wVerifyIpv4, wVerifyIpv6, wVerifyIDFA, wVerifyIMEI, wVerifyOAID } from '@/plugins/verifier'
import { mapGetters } from 'vuex'
import HandleData from '@/service/Activity'

export default {
  name: 'ActivityTestForm',
  data () {
    return {
      form: {
        divice_id: '',
        report_conver: 1,
        divice_type: 1,
        activity_id: '',
        url: '',
        ip: ''
      },
      diviceRules: [
        {
          validator (rule, val, res) {
            if (val === '') {
              res('请填写IMEI设备码')
            }
            if (!wVerifyIMEI(val)) {
              res('请填写正确格式的IMEI码')
            }
            res()
          },
          trigger: 'blur',
          required: true
        },
        {
          validator (rule, val, res) {
            if (val === '') {
              res('请填写OAID设备码')
            }
            if (!wVerifyOAID(val)) {
              res('请填写正确格式的OAID码')
            }
            res()
          },
          trigger: 'blur',
          required: true
        },
        {
          validator (rule, val, res) {
            if (val === '') {
              res('请填写IDFA设备码')
            }
            if (!wVerifyIDFA(val)) {
              res('请填写正确格式的IDFA码')
            }
            res()
          },
          trigger: 'blur',
          required: true
        }
      ],
      rules: {
        divice_id: [],
        divice_type: [{
          message: '请选择设备码类型',
          trigger: 'change',
          required: true
        }],
        report_conver: [{
          message: '请选择是否需要回调转化',
          trigger: 'change',
          required: true
        }],
        ip: [{
          validator (rule, val, res) {
            console.log(val !== '')
            if (val !== '' && !wVerifyIpv4(val) && !wVerifyIpv6(val)) {
              res('请填写正确的IP地址')
            }
            res()
          },
          trigger: 'blur'
        }]
      },
      reportConver_list: [
        {
          name: '是',
          id: 1
        },
        {
          name: '否',
          id: 0
        }
      ]
    }
  },
  methods: {
    reportChange (val) {
      this.$emit('report-change', val)
    },
    copyUrl () {
      return copyText(this.form.url)
    },
    diviceChange (val) {
      this.rules.divice_id = this.diviceRules[val - 1]
      this.$refs.form.clearValidate()
    },
    submit () {
      this.$refs.form.validate(async v => {
        if (v) {
          const payload = new HandleData(this.form).appTestAdd()
          const data = await this.$wPost('/app/activity/test/add.do', payload)
          if (data !== false) {
            this.$wToast.success('上报成功')
            this.$emit('add')
            this.$set(this.form, 'report_conver', 1)
            this.$set(this.form, 'divice_type', 1)
            this.$set(this.form, 'ip', '')
            this.$set(this.form, 'divice_id', '')
            this.rules.divice_id = this.diviceRules[0]
          }
          this.$refs.submitButton.updatedLoading(false)
        } else {
          this.$refs.submitButton.updatedLoading(false)
        }
      })
    }
  },
  computed: {
    ...mapGetters([
      'enum_map'
    ])
  },
  beforeMount () {
    console.log(this.$route.query)
    this.$set(this.form, 'activity_id', this.$route.params.activity_id)
    this.$set(this.form, 'url', this.$route.query.url)
    this.rules.divice_id = this.diviceRules[0]
    console.log(this.form)
  }
}
