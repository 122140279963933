/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-18 10:59:21
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-18 11:02:47
 */
import ActivityTestForm from './ActivityTestForm.vue'
export default ActivityTestForm
