/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-18 10:10:05
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-06 17:02:18
 */
import ActivityTestForm from './ActivityTestForm'
import EnumMap from '@/plugins/enumMap'
export default {
  name: 'ActivityTest',
  components: {
    ActivityTestForm
  },
  data () {
    return {
      queryForm: {
        activity_id: ''
      },
      isUnReport: false
    }
  },
  methods: {
    tableSearch (reset) {
      if (reset) {
        this.$refs['w-table'].resetList()
      } else {
        this.$refs['w-table'].getList()
      }
    },
    reportChange (val) {
      if (val === 2) {
        this.isUnReport = true
      } else {
        this.isUnReport = false
      }
    },
    del (id) {
      const self = this
      self.$confirm('您确认删除此上报信息吗？', '', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'w-table-delete'
      }).then(async () => {
        self.$refs['w-table'].updateLoading(true)
        const payload = Object.assign({}, {
          id
        })
        const data = await self.$wPost('/app/activity/test/delete.do', payload)
        if (data === true) {
          self.$wToast.success('删除成功')
          self.tableSearch(true)
        } else {
          self.$refs['w-table'].updateLoading(false)
        }
      }).catch(() => {});
    },
    activityTestStatus (val) {
      const name = new EnumMap('activity_test_status').mapping(val)
      return name
    }
  },
  beforeMount () {
    this.$set(this.queryForm, 'activity_id', Number(this.$route.params.activity_id))
  }
}
